"use client";
import React from "react";
import { IH5Combination } from "@/store/modules/pageLayout";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import H5CombinationText from "./H5CombinationText";
import FmLink from "@/ui-component/basic/FmLink";
import { gaCommonEvent } from "@/src/utils/gtagEvents";
import { useUserInfoContext } from "@/src/provider/UserInfo/context";
import { isLogin } from "@/src/models/loginModel";
import FmImage from "@/ui-component/basic/FmImage";
import Slider from "@/src/components/Slider";

interface IProps {
    H5Combination: IH5Combination;
    newAdList: any[];
    isAutoplay: boolean;
    index: number;
}

const H5CombinationSwiper: React.FC<IProps> = (props) => {
    const { H5Combination, newAdList = [], isAutoplay, index } = props;
    const { type = 1 } = H5Combination;
    const [userInfo] = useUserInfoContext((state) => [state.data]);
    const renderItem = (item: any,i:number) => {
        return (
            <div
                className={classnames(
                    stl["h5-combination-container"],
                    item.className,
                )}
                key={i}
            >
                <div key={index}>
                    <FmLink
                        href={item.link}
                        onClickCapture={() => {
                            gaCommonEvent(
                                item.burial
                                    ? item.burial
                                    : `smallad_${type}_${index + 1}`,
                                {
                                    event_label: `smallad_${type}_${index + 1}_${
                                        userInfo && userInfo.is_old_customer
                                            ? "old"
                                            : "new"
                                    }${isLogin() ? "_login" : ""}`,
                                },
                            );
                        }}
                    >
                        <FmImage image={item["img"]} lazyLoadingAnimation />
                    </FmLink>
                    <H5CombinationText type={type} item={item} />
                </div>
            </div>
        );
    };

    return (
        <Slider
            sliders={newAdList}
            renderItem={renderItem}
            itemWidth={index === 2 ? 3.46 : 3.46 * 2}
            type={"rem"}
            isAutoplay={isAutoplay}
            dots
            slidesConfig={{
                initial: 0,
                loop: true,
                slides: {
                    perView: index,
                    spacing: 0.15,
                },
            }}
        />
    );
};

export default H5CombinationSwiper;
