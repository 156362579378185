"use client";
import { IH5CommentArea } from "@/store/modules/pageLayout";
import React from "react";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import CommentSlider from "./CommentSlider";

export interface IH5CommentAreaClient {
    H5CommentArea: IH5CommentArea;
}

const H5CommentAreaClient: React.FC<IH5CommentAreaClient> = (props) => {
    const { H5CommentArea } = props;
    const { describe, list, title, isAutoplay } = H5CommentArea;
    
    return (
        <div className={classnames(stl["comment-area-container"])}>
            <div
                className={classnames(stl["comment-area-title"])}
                dangerouslySetInnerHTML={{ __html: title }}
            ></div>
            <div
                className={classnames(stl["comment-area-describe"])}
                dangerouslySetInnerHTML={{ __html: describe }}
            ></div>
            <CommentSlider newAdList={list} isAutoplay={isAutoplay} />
        </div>
    );
};

export default H5CommentAreaClient;
