"use client";
import React, { useMemo, useEffect, useState } from "react";
import { IH5ProductRecommendations } from "@/store/modules/pageLayout";
import stl from "../../styles/frontPage.module.scss";
import Slider from "@/src/components/Slider";
import classnames from "classnames";
import FmLink from "@/ui-component/basic/FmLink";
import FmImage from "@/ui-component/basic/FmImage";
import { jointImg } from "@/lib/commonService";
import { useRateContext } from "@/src/provider";

interface IProps {
    newAdList: any[];
    H5ProductRecommendations: IH5ProductRecommendations;
}

const RenderItem = ({
    item,
    index,
    H5ProductRecommendations,
}: {
    item: any;
    index: number;
    H5ProductRecommendations: IH5ProductRecommendations;
}) => {
    const [getPrice] = useRateContext((state) => [state.getPrice]);

    return (
        <div
            className={classnames(
                stl["areaCart"],
                `type-${index}`,
                item.className,
            )}
            style={item.style}
            key={index}
        >
            <FmLink
                href={`/pc/productDetail/${item.products_id}?color=${item.products_color_relation?.[0]?.color_id}`}
                target="_blank"
            >
                <FmImage
                    image={jointImg({
                        productId: item.products_id,
                        size: "middle",
                        imgTotal: 2,
                        name: item.products_color_relation?.[0]?.color_image,
                    })}
                    lazyLoadingAnimation
                />
            </FmLink>
            <div className={classnames(stl["styleColl"])}>
                {H5ProductRecommendations.styles} :
                {item.products_name_attribute || item.products_model}
            </div>
            <div className={classnames(stl["price-wrapper"])}>
                {item.products_color_relation &&
                    item.products_color_relation.length > 0 &&
                    item.products_color_relation[0].product_price
                        .discount_price && (
                        <div className={classnames(stl["priceColl"])}>
                            {getPrice(
                                item.products_color_relation[0].product_price
                                    .price,
                            )}
                        </div>
                    )}
                {item.products_color_relation &&
                    item.products_color_relation.length > 0 &&
                    item.products_color_relation[0].product_price.price && (
                        <div className={classnames(stl["price"])}>
                            {getPrice(
                                item.products_color_relation[0].product_price
                                    .discount_price
                                    ? item.products_color_relation[0]
                                          .product_price.discount_price
                                    : item.products_color_relation[0]
                                          .product_price.price,
                            )}
                        </div>
                    )}
            </div>
        </div>
    );
};

const SliderTemp: React.FC<IProps> = ({
    newAdList = [],
    H5ProductRecommendations,
}) => {
    const sliders = useMemo(() => {
        const slidersList: any[] = [];
        const tempSliders = newAdList.filter((item) => item.products_model);
        if (H5ProductRecommendations.type === 2) {
            let temp = 0;
            tempSliders.forEach((element, index) => {
                temp = Math.floor(index / 2);
                if (!Array.isArray(slidersList[temp])) {
                    slidersList[temp] = [];
                }
                slidersList[temp].push(element);
            });
            return slidersList;
        }
        return tempSliders;
    }, [newAdList, H5ProductRecommendations]);

    const itemWrapper = (item: any, index: number) => {
        const { className, ...rest } = item;
        return H5ProductRecommendations.type === 2 ? (
            <div
                className={classnames(stl["item-wrapper"], className)}
                key={index}
            >
                <RenderItem
                    item={rest[0]}
                    index={index + 0}
                    H5ProductRecommendations={H5ProductRecommendations}
                />
                <RenderItem
                    item={{ style: { marginTop: "0.2rem" }, ...rest[1] }}
                    index={index + 1}
                    H5ProductRecommendations={H5ProductRecommendations}
                />
            </div>
        ) : (
            <RenderItem
                item={item}
                index={index}
                key={index}
                H5ProductRecommendations={H5ProductRecommendations}
            />
        );
    };

    return (
        <Slider
            className="H5ProductRecommendations"
            sliders={sliders}
            renderItem={itemWrapper}
            itemWidth={3.44}
            slidesConfig={{
                initial: 0,
                loop: true,
                drag:
                    (H5ProductRecommendations.type === 2 &&
                        newAdList.length <= 4) ||
                    (H5ProductRecommendations.type === 1 &&
                        newAdList.length <= 2)
                        ? false
                        : true,
                slides: {
                    perView: 2,
                    spacing: 0.2,
                },
            }}
            sliderStyle={{
                padding: "0.6rem 0 0.4rem",
                boxSizing: "content-box",
                margin: "0 calc((100vw - var(--slider-wrapper-width)) / 2)",
            }}
            dotsStyle={{
                dots: {
                    style: {
                        padding: 0,
                    },
                },
            }}
            dots={
                (H5ProductRecommendations.type === 2 &&
                    newAdList.length <= 4) ||
                (H5ProductRecommendations.type === 1 && newAdList.length <= 2)
                    ? false
                    : true
            }
            type={"rem"}
        />
    );
};

export default SliderTemp;
