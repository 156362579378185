import React, { use, useEffect } from "react";
import stl from "../../styles/components.module.scss";
import classnames from "classnames";
import Slider from "@/src/components/Slider";
import { FmLink } from "@/ui-component";
import { useTranslation } from "@/src/i18n/client";
import Video from "../Video";

interface IProps {
    videos: any[];
}

const UserVideo: React.FC<IProps> = ({ videos = [] }) => {
    const { t } = useTranslation("common");

    const renderItem = (item: any, index: number) => {
        return (
            <div
                key={index}
                className={classnames(stl["videos-item"], item.className)}
            >
                {item.url.indexOf("www.youtube.com") > 0 ? (
                    <iframe
                        className={stl["videos-iframe"]}
                        src={item.url}
                        allow="autoplay; encrypted-media"
                    ></iframe>
                ) : (
                    <Video autoPlay={false} loop={false} url={item.url} />
                )}
                {item.title && (
                    <div
                        className={stl["item-title"]}
                        dangerouslySetInnerHTML={{
                            __html: item.title,
                        }}
                    ></div>
                )}
                <div
                    className={classnames(
                        "default_layout",
                        stl["item-title"],
                        stl["default-layout-extra-config"],
                    )}
                >
                    {item.ids && (
                        <div>
                            <span>
                                {t("common:FRAME")}:{" "}
                                <FmLink href={item.link}>{item.ids}</FmLink>
                            </span>
                        </div>
                    )}
                    {item.author && (
                        <div className={stl["author"]}>@{item.author}</div>
                    )}
                </div>
            </div>
        );
    };

    return (
      <div className={stl["render-videos-container"]}>
            <div
                className={classnames(
                    "default_layout",
                    stl["render-videos-link-container"],
                )}
            >
                <FmLink
                    className={stl["render-videos-link"]}
                    href="/z/customershowpage.html"
                >
                    {t("common:View_More")}
                </FmLink>
            </div>
            <Slider
                renderItem={renderItem}
                sliders={videos}
                itemWidth={4.8}
                type={"rem"}
                isAutoplay={true}
                slidesConfig={{
                    initial: 0,
                    loop: true,
                    slides: {
                        perView: 1.5,
                        spacing: 0.1,
                    },
                }}
                dots
            />
        </div>
    );
};

export default UserVideo;
