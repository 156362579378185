"use client";
import React from "react";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import Slider from "@/src/components/Slider";
import FmDivider from "@/ui-component/basic/FmDivider";
import FmAvatar from "@/ui-component/basic/FmAvatar";

interface IProps {
    newAdList: any[];
    isAutoplay: boolean;
}

const CommentSlider: React.FC<IProps> = ({ newAdList = [], isAutoplay }) => {
    const renderItem = (item: any, index: number) => {
        return (
            <div
                className={classnames(
                    stl["area-cart-container"],
                    item.className,
                )}
                key={index}
            >
                <div className={classnames(stl["area-cart-warrper"])}>
                    <div className={classnames(stl["area-cart-content"])}>
                        <div
                            className={classnames(
                                stl["area-cart-user-wrapper"],
                            )}
                        >
                            <div className={classnames(stl["area-cart-user"])}>
                                <FmAvatar alt="" src={item.bgimg} />
                                <div
                                    className={classnames(
                                        stl["area-avatar-username"],
                                    )}
                                >
                                    <div
                                        className={classnames(
                                            stl["area-auths-tit"],
                                        )}
                                    >
                                        {item.name}
                                    </div>
                                    <div
                                        className={classnames(
                                            stl["area-auths-des"],
                                        )}
                                    >
                                        {item.evaluation}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FmDivider sx={{
                            margin: "0.15rem 0",
                        }} />
                        <div className={classnames(stl["area-tit-narrate"])}>
                            {item.narrate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Slider
            renderItem={renderItem}
            sliders={newAdList}
            type={"rem"}
            itemWidth={4.8}
            isAutoplay={isAutoplay}
            slidesConfig={{
                initial: 0,
                loop: true,
                slides: {
                    perView: 1.5,
                    spacing: 0.1,
                },
            }}
        />
    );
};

export default CommentSlider;
