"use client";
import React, { CSSProperties, useMemo } from "react";
import classnames from "classnames";
import stl from "../../styles/frontPage.module.scss";
import FmLink from "@/ui-component/basic/FmLink";

interface IProps {
    item: any;
    type: number;
}

const H5CombinationText: React.FC<IProps> = (props) => {
    const { item, type } = props;

    const isPrimary = useMemo(
        () => item.btnType == 5 || item.btnType == 6 || item.btnType == 7,
        [],
    );

    const styleMemo = useMemo<CSSProperties>(() => {
        return {
            "--btn-color": item.btn_bg_color,
        } as CSSProperties;
    }, []);

    return (
        item.title && (
            <div
                className={classnames(stl["combination-text-container"], {
                    [stl["fixed-text"]]: type === 3,
                    [stl["mask"]]: item.isMask,
                })}
            >
                <div
                    className={stl["title"]}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                ></div>
                <div
                    className={stl["text"]}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                ></div>
                {item.btnType != 1 && (
                    <FmLink href={item.link}>
                        <div
                            className={classnames(
                                "default_layout",
                                stl["shop-btn"],
                                {
                                    [stl["shop-btn-mb-1"]]: ["2", "5"].includes(
                                        item.btnType,
                                    ),
                                    [stl["shop-btn-mb-2"]]: ['3', '6'].includes(item.btnType
                                    ),
                                    [stl["shop-btn-mb-3"]]: ![
                                        '2', '3', '5', '6',
                                    ].includes(item.btnType),
                                    [stl["primary-button"]]: isPrimary,
                                    [stl[`padding-${type === 1 ? 1 : 4}`]]:
                                        true,
                                },
                            )}
                            style={{
                                background: isPrimary ? item.btn_bg_color : "",
                                ...styleMemo,
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: item.shopBtn,
                                }}
                            ></div>
                        </div>
                    </FmLink>
                )}
            </div>
        )
    );
};

export default H5CombinationText;
