"use client";
import React from "react";
import { IH5UserRotation } from "@/store/modules/pageLayout";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import UserRotationSlider from "./UserRotationSlider";

export interface IH5UserRotationClient {
    H5UserRotation: IH5UserRotation;
}

function H5UserRotationClient(props: IH5UserRotationClient) {
    const { H5UserRotation } = props;
    const { describe, list, title, isAutoplay } = H5UserRotation;
    return (
        <div className={classnames(stl["user-rotation-container"])}>
            <div
                className={classnames(stl["user-rotation-title"])}
                dangerouslySetInnerHTML={{ __html: title }}
            ></div>
            <div
                className={classnames(stl["user-rotation-describe"])}
                dangerouslySetInnerHTML={{ __html: describe }}
            ></div>
            <UserRotationSlider newAdList={list} isAutoplay={isAutoplay} />
        </div>
    );
}

export default H5UserRotationClient;
