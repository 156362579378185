/*
 * 首页商品展示列表客户端
 */
"use client";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { productShow, getProductItemParams } from "@/src/utils";
import { H5Product1 } from "@/store/modules/pageLayout";
import { FmLink, FmListItem, FmTabs, FmViewList } from "@/ui-component";
import stl from "../../styles/frontPage.module.scss";
import HomeProductItem from "../ProductPanel/HomeProductItem";
import { useTranslation } from "@/src/i18n/client";
import ProductEmp from "../ProductEmp";
import classnames from "classnames";
import CompTryOn from "@/src/components/CompTryOn";
import { tryOn } from "@/store";
import { indexProducts } from "@/src/models/loginModel";

interface IProducts {
    news: any[];
    hot: any[];
}
interface IProps {
    H5Product1: H5Product1;
    productList: any[];
    componentUniqueId: string;
}

const ProductPanel1Client: React.FC<IProps> = (props) => {
    const { H5Product1, productList,componentUniqueId } = props;
    const [initialPage, setInitialPage] = useState(0);
    const [products, setProducts] = useState<IProducts>({
        news: [],
        hot: [],
    });
    const { t } = useTranslation("common");
    const closeTryOn = tryOn.useTryonStore((state) => state.closeTryOn);
    const googleAnalytics = useCallback(() => {
        const { hot, news } = products;

        setTimeout(() => {
            productShow({
                item_list_id: "home hot products",
                item_list_name: "home hot products",
                items: getProductItemParams({ productList: hot }),
            });
            productShow({
                item_list_id: "home news products",
                item_list_name: "home news products",
                items: getProductItemParams({ productList: news }),
            });
        }, 0);
    }, [products]);

    useEffect(() => {
        getCmsData();
    }, [productList]);

    useEffect(() => {
        // product在useEffect里更新，会执行googleAnalytics
        googleAnalytics();
    }, [googleAnalytics, products]);

    const getPreData = async () => {
        const arg = await indexProducts({ limit: 8, type: true });
        setProducts(arg);
    };
    const getCmsData = () => {
        const arg = productList.find(
            (item: any) =>
                item["componentUniqueId"] === componentUniqueId,
        );
        if (!arg) {
            getPreData();
            return;
        }
        if (
            !(arg["hot"] || arg["news"]) ||
            (arg["hot"].length <= 0 && arg["news"].length <= 0)
        ) {
            getPreData();
            return;
        }

        let firstHalf = [];
        let firstHalfBest = [];

        firstHalf = arg["news"].slice(0, 8);
        firstHalfBest = arg["hot"].slice(0, 8);

        setProducts({
            news: firstHalf,
            hot: firstHalfBest,
        });
    };

    const onTabClick = (e: any, val: number): void => {
        setInitialPage(val);
    };

    const { order = 1, showbest = true, shownew = true } = H5Product1;
    //数据初始化
    const initTabsData: any = useMemo(
        () => [
            {
                id: 0,
                title: H5Product1.new.title,
                val: "news",
                products: products["news"], // 这块用的不是cms的数据，是后面客户端请求的
                isShow: shownew,
                href: H5Product1.new.shopAll,
            },
            {
                id: 1,
                title: H5Product1.best.title,
                val: "hot",
                isShow: showbest,
                products: products["hot"], // 这块用的不是cms的数据，是后面客户端请求的
                href: H5Product1.best.shopAll,
            },
        ],
        [
            H5Product1.best.shopAll,
            H5Product1.best.title,
            H5Product1.new.shopAll,
            H5Product1.new.title,
            products,
            showbest,
            shownew,
        ],
    );
    const renderRow = (data: any) => {
        return data.map((props: any, index: number) => {
            return (
                <FmListItem className={stl["double-list"]} key={index}>
                    {props.map((item: any, key: number) => (
                        <HomeProductItem {...item} key={key} />
                    ))}
                </FmListItem>
            );
        });
    };

    const tabsData = useMemo(() => {
        //隐藏的数据不展示
        const tempTabsData = initTabsData.filter((item: any) => item.isShow);

        if (tempTabsData.length < 1) {
            return null;
        }
        //排序
        if (order != 1) {
            tempTabsData.reverse();
        }

        //重置id
        return tempTabsData.map((item: any) => {
            const data = item.products.reduce(
                (result: any[], product: any, index: number) => {
                    if (index % 2 === 0) {
                        const chunk: any = item.products.slice(
                            index,
                            index + 2,
                        );
                        chunk.id = `${product.products_id}-${Math.random()}`;
                        result.push(chunk);
                    }
                    return result;
                },
                [],
            );
            return {
                value: item.val,
                label: item.title,
                className: stl["tab-bar"],
                classes: {
                    root: stl["tab-root"],
                    selected: stl["tab-selected"],
                    indicator: stl["tab-indicator"],
                },
                children: (
                    <>
                        <FmViewList
                            className={stl["viewlist-container"]}
                            currentPage={1}
                            total={data.length}
                            pageSize={data.length}
                            noDataTips={<ProductEmp />}
                            hasListItem
                        >
                            {/* 取代renderrow */}
                            {renderRow(data)}
                        </FmViewList>
                        <FmLink
                            href={item.href}
                            className={classnames(stl["view-all"], "view-all")}
                            icon={"icon--arrow-right"}
                        >
                            {t("common:ShopAll")}
                        </FmLink>
                    </>
                ),
            };
        });
    }, [initTabsData, order, t]);
    return (
        <div className={stl["home-product-panel"]}>
            {H5Product1[`new`].title && H5Product1[`best`].title ? (
                <FmTabs
                    variant={"fullWidth"}
                    tabPanelClassName={stl["tab-panel-content"]}
                    tabs={tabsData}
                    value={initialPage}
                    onChange={onTabClick}
                    tabsClassName={classnames(
                        stl["tab-bar-container"],
                        "tab-bar-container",
                    )}
                    classes={{
                        indicator: stl["tab-indicator"],
                    }}
                    carousel
                />
            ) : !H5Product1[`new`].title ? (
                <div>2</div>
            ) : (
                <div>3</div>
            )}

            <CompTryOn onClose={closeTryOn} />
        </div>
    );
};

export default ProductPanel1Client;
