"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserImageShow, {
    getNewArrayByPerPage,
    typeParse,
} from "./UserImageShow";
import { useTranslation } from "@/src/i18n/client";
import { FmTabs } from "@/ui-component";
import stl from "../../styles/components.module.scss";
import classnames from "classnames";
import { getCustomerShow } from "@/src/models/customerShow";
import { IDataItem } from "./CustomerShow";
import { isObject } from "lodash";
import UserVideo from "./UserVideo";

interface IImg {
    img: string;
    link: string;
}

interface IVideos {
    author: string;
    link: string;
    ids: string;
    title: string;
    url: string;
}
interface IH5Show {
    all: string;
    img3: IImg[];
    visible: boolean;
    title2visit: boolean;
    title1: string;
    title2: string;
    videos: IVideos[];
    type: string; // "1"
    title: string;
    title1visit: boolean;
    text: string;
    img2: IImg[];
    img1: {
        leftimg2: IImg[];
        bottomimg: IImg[];
        leftimg1: IImg[];
    };
}
interface IProps {
    H5Show: IH5Show;
}

const CustomShow: React.FC<IProps> = (props) => {
    const { t } = useTranslation("common");
    const [currentTab, setCurrentTab] = useState(0);
    const { H5Show } = props;
    const {
        title1visit,
        title2visit,
        title1,
        title2,
        videos,
        type,
        title,
        text,
    } = useMemo(() => H5Show, [H5Show]);
    const [showData, setShowData] = useState<IDataItem[][]>([]);
    const [loading, setLoading] = useState(true);
    const getData = useCallback(async () => {
        const arg = await getCustomerShow(typeParse[type] * 10);

        let argArr: IDataItem[] = [];
        if (isObject(arg)) {
            argArr = Object.values(arg);
        } else {
            argArr = arg;
        }
        // 这里做个处理：因为用户秀是一次请求十页，做的假轮播，所以一共请求了type * perPage * 10条数据，所以这里要拆分成total / (type * perpage)条数据表示对应页码
        const splicedArr = getNewArrayByPerPage(argArr, typeParse[type]);
        setShowData(splicedArr);
        setLoading(false);
    }, [type]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tabsData = useMemo(() => {
        return [
            {
                value: 0,
                label: title1,
                children: (
                    <UserImageShow
                        type={type}
                        showData={showData}
                        loading={loading}
                    />
                ),
                classes: {
                    root: stl["tab-item"],
                },
            },
            {
                value: 1,
                label: title2,
                children: currentTab == 1 && <UserVideo videos={videos} />,
                classes: {
                    root: stl["tab-item"],
                },
            },
        ];
    }, [loading, videos, showData, title1, title2, type, currentTab]);

    return (
        <>
            {!(title1visit || title2visit) ? null : (
                <div
                    className={classnames(
                        stl["custom-show-container-pay-attention-not-customer"],
                        "custom-show-container-pay-attention-not-customer",
                    )}
                >
                    <div
                        className={stl["h5-show-title"]}
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></div>
                    <div
                        className={stl["h5-show-text"]}
                        dangerouslySetInnerHTML={{ __html: text }}
                    ></div>

                    {title1visit && !title2visit && (
                        <UserImageShow
                            type={type}
                            showData={showData}
                            loading={loading}
                        />
                    )}
                    {!title1visit && title2visit && (
                        <UserVideo videos={videos} />
                    )}
                    {title1visit && title2visit && (
                        <FmTabs
                            tabs={tabsData}
                            value={currentTab}
                            className={classnames(
                                stl["tabs-container"],
                                "tabs-container",
                            )}
                            onChange={(e, value) => setCurrentTab(value)}
                            centered
                            textColor={"primary"}
                            indicatorColor={"primary"}
                            tabsClassName={classnames(
                                stl["tabs-bar-container"],
                                "tabs-bar-container",
                            )}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default CustomShow;
