import React, { useState, useRef, useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./VideoPlay.css";

interface Props {
    src: string;
    poster?: string;
    style?: any;
    loop?: boolean;
    controls?: boolean;
    autoplay?: boolean;
    muted?: boolean;
    playsInline?: boolean;
    onStart?: () => void;
    onEnd?: () => void;
    onRef?: (ref?: any) => void;
}

export default function VideoPlay(props: Props) {
    const {
        poster = "",
        src = "",
        style = {},
        loop = false,
        autoplay = true,
        controls = true,
        muted = false,
        playsInline = false,
        onStart,
        onEnd,
        onRef,
    } = props;
    const videoRef = useRef(null);
    const playerRef: any = useRef(null);
    const [option, setOptopm] = useState({});

    const onReadyPlay = (palyer: any) => {
        videoRef.current = palyer;
        autoplay && palyer.play();
        onRef && onRef(palyer);
        onStart && onStart();
        if (palyer.id && playsInline) {
            let ele: any = document.getElementById(palyer.id() + "_html5_api");
            ele && ele.setAttribute("playsInline", "true");
        }

        palyer.on("ended", () => {
            onEnd && onEnd();
            console.log("player will ended");
        });
    };

    const init = () => {
        let _option = {
            controls: controls,
            autoplay: autoplay, //加载完成是否自动播放
            loop: loop, //视频播放结束后，是否循环播放
            notSupportedMessage: "此视频暂无法播放，请稍后再试",
            poster: poster, //视频封面
            muted: muted, //静音播放
            controlBar: {
                // timeDivider: true,//是否显示时间控制条，默认为true
                // remainingTimeDisplay: false,//是否显示剩余时间，默认为true
                // fullscreenToggle: true // 全屏按钮
                children: [
                    //自定义
                    { name: "playToggle" }, // 播放按钮
                    {
                        name: "volumePanel", // 音量控制
                        inline: false, // 不使用水平方式
                    },
                    { name: "currentTimeDisplay" }, // 当前已播放时间
                    { name: "durationDisplay" }, // 总时间
                    { name: "progressControl" }, // 播放进度条
                    {
                        name: "pictureInPictureToggle", //支持画中画
                    },
                    {
                        name: "FullscreenToggle", //支持全屏
                    },
                ],
            },
        };
        setOptopm(_option);

        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = (playerRef.current = videojs(
                videoElement,
                _option,
                () => {},
            ));
            onReadyPlay(player);
        }
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="video_play">
            <video
                style={style}
                ref={videoRef}
                className="video-js vjs-big-play-centered"
            >
                <source src={src} type="video/mp4" />
            </video>
        </div>
    );
}
