"use client";
import { IH5ProductRecommendations } from "@/store/modules/pageLayout";
import React, { useCallback, useEffect, useState } from "react";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import CountDownCms from "@/src/components/CountDown/CountDownCms";
import SliderTemp from "./SliderTemp";
import FmLink from "@/ui-component/basic/FmLink";

export interface IH5ProductRecommendClientProps {
    productList: any[];
    componentUniqueId: string;
    H5ProductRecommendations: IH5ProductRecommendations;
}

const H5ProductRecommendClient: React.FC<IH5ProductRecommendClientProps> = (
    props,
) => {
    const {
        productList = [],
        H5ProductRecommendations,
        componentUniqueId,
    } = props;
    const {
        bgcolor,
        bgimg,
        titleColor,
        title,
        iscountdown,
        countdownUs,
        calligraphy,
        countdownBackgroundColor,
        ends,
        shopMore,
        link,
    } = H5ProductRecommendations;
    const [products, setProducts] = useState<any[]>([]);
    let start_time =
        countdownUs && countdownUs[0]
            ? new Date(countdownUs[0]).getTime() / 1000
            : 0;
    let expire_time =
        countdownUs && countdownUs[0]
            ? new Date(countdownUs[1]).getTime() / 1000
            : 0;
    // @ts-ignore
    let now = parseInt(new Date().getTime() / 1000);

    const getProductsData = useCallback(() => {
        const targetItem = productList.find(
            (item) => item["componentUniqueId"] === componentUniqueId,
        );
        if (!targetItem || !targetItem.recommend2?.length) {
            setProducts([]);
            return;
        }
        setProducts(targetItem.recommend2);
    }, [productList, componentUniqueId]);

    useEffect(() => {
        getProductsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return products?.length > 0 ? (
        <div
            className={classnames(stl["product-recommend-container"])}
            style={{
                background: bgcolor ? bgcolor : `url('${bgimg}') no-repeat`,
                backgroundSize: "100% 100%",
            }}
        >
            {iscountdown && now > start_time && now < expire_time ? (
                <div className={classnames(stl["countdown-wrapper"])}>
                    <div
                        className={classnames(stl["title"])}
                        style={{ color: titleColor }}
                    >
                        {title}
                    </div>
                    <CountDownCms
                        startTime={start_time}
                        lastTime={expire_time}
                        primary="dark"
                        calligraphy={calligraphy}
                        countdownBackgroundColor={countdownBackgroundColor}
                    >
                        <div
                            className={classnames(stl["title"])}
                            style={{ color: titleColor }}
                        >
                            {ends}
                        </div>
                    </CountDownCms>
                </div>
            ) : (
                <div
                    className={classnames(stl["title"], stl["only-title"])}
                    style={{ color: titleColor }}
                >
                    {title}
                </div>
            )}
            <div className={classnames(stl["product-list-wrapper"])}>
                {products?.length > 0 ? (
                    <SliderTemp
                        newAdList={products}
                        H5ProductRecommendations={H5ProductRecommendations}
                    />
                ) : (
                    <></>
                )}
            </div>
            <div className={classnames(stl["btn-wrapper"])}>
                <FmLink href={link} className={classnames(stl["shop-more"])}>
                    <div className={classnames(stl["shop-more-btn"])}>
                        {shopMore}
                    </div>
                </FmLink>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default H5ProductRecommendClient;
