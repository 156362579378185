"use client";
import Slider from "@/src/components/Slider";
import React, { Fragment, useState } from "react";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import { FmDialog, FmIcon, FmImage } from "@/ui-component";
import VideoPlay from "@/src/productDetails/ProductAccordion/VideoPlay";

interface IProps {
    newAdList: any[];
    isAutoplay: boolean;
}

const UserRotationSlider: React.FC<IProps> = ({
    newAdList,
    isAutoplay = false,
}) => {
    const [visit, setVisit] = useState(false);
    const [videourl, setVideourl] = useState("");

    const renderItem = (item: any, index: number) => {
        return (
            <div
                className={classnames(
                    stl["user-rotation-item"],
                    item.className,
                )}
                key={index}
            >
                <div
                    className={classnames(stl["big-box"])}
                    onClick={() => {
                        window.open(item.link, "_blank");
                    }}
                >
                    <FmImage image={item.imgsrc} alt="user-rotation" />
                    <div className={classnames(stl["word-box"])}></div>
                    <div className={classnames(stl["word-box-tie"])}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <div className={classnames(stl["word-box-title"])}>
                                {item.title}
                            </div>
                            <div className={classnames(stl["word-box-tdes"])}>
                                {item.Copywriting}
                            </div>
                        </div>
                    </div>
                    {item.type == 1 && (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setVisit(true);
                                setVideourl(item.videosrc);
                            }}
                        >
                            <FmIcon
                                icon={"icon--video"}
                                className={classnames(stl["word-play"])}
                                style={{ color: "#fff", fontSize: "0.72rem" }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Slider
                sliders={newAdList}
                type={"rem"}
                slidesConfig={{
                    initial: 0,
                    loop: true,
                    slides: {
                        perView: 1.5,
                        spacing: 0.15,
                    },
                }}
                renderItem={renderItem}
                isAutoplay={isAutoplay}
            />
            {visit && (
                <FmDialog
                    open={visit}
                    closeable={true}
                    slideUp={true}
                    onClose={() => {
                        setVisit(false);
                        setVideourl("");
                    }}
                    iconClassName={classnames(stl["video-right-close"])}
                >
                    <div className={classnames(stl["video-wrapper"])}>
                        <VideoPlay
                            src={videourl}
                            controls={true}
                            loop={true}
                            autoplay={false}
                            muted={true}
                            style={{
                                width: "100vw",
                                height: "87vh",
                            }}
                        />
                    </div>
                </FmDialog>
            )}
        </Fragment>
    );
};

export default UserRotationSlider;
